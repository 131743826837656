exports.components = {
  "component---src-components-portfolio-page-portfolio-page-container-js": () => import("./../../../src/components/PortfolioPage/PortfolioPageContainer.js" /* webpackChunkName: "component---src-components-portfolio-page-portfolio-page-container-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-appcraftlab-js": () => import("./../../../src/pages/appcraftlab.js" /* webpackChunkName: "component---src-pages-appcraftlab-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-prodvizhenie-mobilnykh-prilozhenij-js": () => import("./../../../src/pages/prodvizhenie-mobilnykh-prilozhenij.js" /* webpackChunkName: "component---src-pages-prodvizhenie-mobilnykh-prilozhenij-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-vacancies-js": () => import("./../../../src/pages/vacancies.js" /* webpackChunkName: "component---src-pages-vacancies-js" */)
}

