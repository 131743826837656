import React from 'react';
import ContextProvider from './src/context/ContextProvider';

import './src/styles/main.css';

export const onClientEntry = () => {
  const scrollbarWidth = window.innerWidth - document.body.clientWidth;
  document.body.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`);

  if (
    process.env.NODE_ENV === `development` &&
    typeof window.ym !== `undefined` &&
    window.location && window.location.search
  ) {
    const { pathname, search, hash } = window.location;
    window.ym(28874515, 'hit', pathname + search + hash);
  }
};

export const wrapRootElement = ({ element }) => {
  return <ContextProvider>{element}</ContextProvider>;
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  const { pathname, search, hash } = location;
  if (process.env.NODE_ENV === 'production' && typeof window.ym !== 'undefined') {
    window.ym(28874515, 'hit', pathname + search + hash);
  }
}
