import React from 'react'
import { createContext, useContext, useReducer } from 'react'

const StateContext = createContext()
const DispatchContext = createContext()

const initialState = {
  services: ['discussion', 'testing', 'warranty'],
  persistentServices: ['discussion', 'testing', 'warranty'],
  phone: null,
}

function reducer(state, action) {
  switch (action.type) {
    case 'ADD_SERVICE':
      return {
        ...state,
        services: [...state.services, action.payload],
      }
    case 'REMOVE_SERVICE':
      return {
        ...state,
        services: state.services.filter((item) => item !== action.payload),
      }
    case 'CHANGE_PHONE':
      return {
        ...state,
        phone: action.payload,
      }
    default:
      return state
  }
}

const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  )
}

export function useContextState() {
  const context = useContext(StateContext)
  if (context === undefined) {
    throw new Error('useContextState must be used within a ContextProvider')
  }
  return context
}

export function useContextDispatch() {
  const context = useContext(DispatchContext)
  if (context === undefined) {
    throw new Error('useContextDispatch must be used within a ContextProvider')
  }
  return context
}

export default ContextProvider
